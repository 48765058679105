
import { CrmFilterOptions } from '@/types/type';
import { computed, defineComponent, nextTick, onMounted, PropType, ref } from 'vue';
export default defineComponent({
  name: 'CrmTable',
  props: {
    data: {
      type: Array as PropType<any[]>,
    },
    id: {
      type: String as PropType<string>,
    },
    columOptions: {
      type: Array as PropType<any[]>,
    },
    layoutHeight: {
      type: Number as PropType<number>,
      default: 680,
    },
    // todo props;
  },
  setup(props, ctx) {
    const table = ref(null);
    const listData = ref([]);

    const tableOption = computed(() => {
      let widthStrong = window.localStorage.getItem(`${props.id}WidthStrong`);
      if (widthStrong) {
        widthStrong = JSON.parse(widthStrong as string);
        const options: CrmFilterOptions[] = [];
        (props.columOptions as CrmFilterOptions[]).forEach((v, index) => {
          // 控制下最小的宽度，不要完全看不清字
          v.width = Number((widthStrong as string)[index]) < 80 ? 80 : Number((widthStrong as string)[index]);
          options.push(v);
        });
        return options;
      } else {
        return props.columOptions;
      }
    });
    const tableHeight = computed(() => {
      return props.layoutHeight - 80;
    });
    const rowClick = (row, column, event) => {
      ctx.emit('row-click', row, column, event);
    };
    const getBind = ({ row, column }, col) => {
      const props = { row, col, column };
      return props;
    };
    const headerDragend = () => {
      nextTick(() => {
        const el = (table.value as any).$el.querySelector('colgroup').querySelectorAll('col');
        const widthStrong: any = [];
        el.forEach((v) => {
          widthStrong.push(v.getAttribute('width'));
        });
        window.localStorage.setItem(`${props.id}WidthStrong`, JSON.stringify(widthStrong));
      });
    };
    const isNull = (row, prop) => {
      const txt = row[prop];
      if (txt === null || txt === '') {
        return '-';
      } else {
        return txt;
      }
    };

    onMounted(() => {
      (table.value as any).doLayout();
    });
    return {
      listData,
      getBind,
      table,
      tableHeight,
      headerDragend,
      tableOption,
      isNull,
      rowClick,
    };
  },
});
