import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38785b8a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "crm-table__warp" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_table, {
      data: _ctx.data,
      class: "crm-table__list",
      border: "",
      style: {"width":"100%"},
      ref: "table",
      onHeaderDragend: _ctx.headerDragend,
      "highlight-current-row": true,
      resizable: true,
      onRowClick: _ctx.rowClick
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableOption, (item, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
            (!item.isHidden)
              ? (_openBlock(), _createBlock(_component_el_table_column, {
                  key: 0,
                  label: item.label,
                  prop: item.propDesc ? item.propDesc : item.prop,
                  "show-overflow-tooltip": "",
                  width: item.width ? item.width : null,
                  fixed: item.fixed ? item.fixed : null,
                  "min-width": item.minWidth ? item.minWidth : null
                }, _createSlots({ _: 2 }, [
                  (item.component)
                    ? {
                        name: "default",
                        fn: _withCtx((scope) => [
                          (_openBlock(), _createBlock(_resolveDynamicComponent(item.component), _normalizeProps(_guardReactiveProps(_ctx.getBind(scope, item))), null, 16))
                        ])
                      }
                    : {
                        name: "default",
                        fn: _withCtx((scope) => [
                          _createTextVNode(_toDisplayString(_ctx.isNull(scope.row, item.propDesc ? item.propDesc : item.prop)), 1)
                        ])
                      }
                ]), 1032, ["label", "prop", "width", "fixed", "min-width"]))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ]),
      _: 1
    }, 8, ["data", "onHeaderDragend", "onRowClick"])
  ]))
}