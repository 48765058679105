import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-588d2610"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "crm-list__warp" }
const _hoisted_2 = {
  class: "crm-table__body",
  ref: "crmListRef"
}
const _hoisted_3 = {
  key: 0,
  class: "list-pagination"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CrmFilter = _resolveComponent("CrmFilter")!
  const _component_CrmTable = _resolveComponent("CrmTable")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CrmFilter, {
      options: _ctx.formatFilterOption,
      onSubmit: _ctx.queryParams,
      onLoad: _ctx.getFilterLoad,
      ref: "crmFilterRef",
      isShowFilter: _ctx.isShowFilter,
      isShowSearch: _ctx.isShowSearch
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "header", {}, undefined, true)
      ]),
      _: 3
    }, 8, ["options", "onSubmit", "onLoad", "isShowFilter", "isShowSearch"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CrmTable, {
        id: _ctx.id,
        data: _ctx.listDatas,
        columOptions: _ctx.isDynamiColumn ? _ctx.dynamicColumn : _ctx.columOptions,
        ref: "crmTableRef",
        onRowClick: _ctx.rowClick
      }, null, 8, ["id", "data", "columOptions", "onRowClick"]),
      (_ctx.isPagination)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_el_pagination, {
              ref: "pageRef",
              total: _ctx.total,
              "current-page": _ctx.pageNum,
              "page-size": _ctx.pageSize,
              "page-sizes": [10, 15, 20, 25, 50, 75, 100],
              layout: "total,sizes, prev, pager, next,jumper",
              background: "",
              onCurrentChange: _ctx.currentChange,
              onSizeChange: _ctx.currentSize
            }, null, 8, ["total", "current-page", "page-size", "onCurrentChange", "onSizeChange"]),
            _renderSlot(_ctx.$slots, "pagination", {}, undefined, true)
          ]))
        : _createCommentVNode("", true)
    ], 512)
  ]))
}