
import { httpPost } from '@/api';
import useLoading, { LoadingType } from '@/hooks/useLoading';
import { store } from '@/store';
import { CrmFilterOptions } from '@/types/type';
import { computed, defineComponent, inject, nextTick, PropType, reactive, ref, toRaw, toRefs } from 'vue';
import CrmFilter from '../CrmFilter/index.vue';
import CrmTable from '../CrmTable/index.vue';
type columnType = {
  label: string | null;
  prop: string | null;
  width: number | null;
  fixed: boolean | null;
};
export default defineComponent({
  components: { CrmFilter, CrmTable },
  props: {
    filterOption: {
      type: Array as PropType<CrmFilterOptions[]>,
    },
    url: {
      type: String as PropType<string>,
      default: '',
    },
    customParams: {
      default: {
        type: Object,
        default: {},
      },
    },
    columOptions: {
      type: Array as PropType<any[]>,
    },
    id: {
      type: String as PropType<string>,
    },
    isDynamiColumn: {
      type: Boolean,
      default: false,
    },
    isPagination: {
      type: Boolean,
      default: true,
    },
    isShowFilter: {
      type: Boolean,
      default: true,
    },
    isShowSearch: {
      type: Boolean,
      default: true,
    },
    rowId: {
      type: String as PropType<string>,
      default: 'id',
    },
    isFundingList: {
      type: Boolean,
      default: false,
    },
    isIndex: {
      type: Boolean,
      default: false,
    },
    queryFn: {
      type: Function as PropType<any>,
      default: null,
    },
    // todo props;
  },
  setup(props, ctx) {
    const { showLoading, closeLoading } = inject<LoadingType>('loading', useLoading());
    const paginationParams = reactive({
      pageNum: 1,
      pageSize: 15,
      total: 0,
    });
    const pageRef = ref();
    const queryForm = ref({});
    const listDatas = ref([]);
    const dynamicColumn = ref<columnType[]>([]);
    const crmListRef = ref(null); // 父元素对象
    const crmTableRef = ref(null); // 表格对象元素
    const crmFilterRef = ref(null);
    const tableLoad = ref(false); // 表格加载
    const filterLoad = ref(false); // 筛选项加载
    const currentFilterData = ref(null);
    const completeLoad = computed(() => {
      // 表格加载且筛选项加载加载完再显示页面，防止抖动
      return filterLoad.value && tableLoad.value;
    });
    const formatFilterOption = computed(() => {
      return props.filterOption?.filter((v) => !v.isHidden);
    });

    const getFilterLoad = (load) => {
      filterLoad.value = load;
    };
    // 设置列表记录缓存
    const setQueryParamsCache = () => {
      store.dispatch('setQueryParamsCache', {
        id: props.id + 'ListQuery',
        data: {
          paginationParams: JSON.parse(JSON.stringify(paginationParams)),
          queryForm: JSON.parse(JSON.stringify(queryForm.value)),
          filterData: JSON.parse(JSON.stringify(currentFilterData.value)),
        },
      });
    };
    const rowClick = (row, column, event) => {
      // 设置激活row
      store.dispatch('setCurrentRow', {
        id: props.id + 'CurrentRow',
        data: toRaw(row),
      });
      ctx.emit('row-click', row, column, event);
    };
    const setCurrentRow = () => {
      store.dispatch('getCurrentRow', props.id + 'CurrentRow').then((row: any) => {
        nextTick(() => {
          if (row) {
            const index = listDatas.value.findIndex((item: any) => {
              return item[props.rowId] === row[props.rowId];
            });
            const Table = (crmTableRef as any).value.table;
            if (index > -1) {
              Table.setCurrentRow(listDatas.value[index]);
            }
          }
        });
      });
    };
    const handleDynamicColumn = (res) => {
      paginationParams.total = Number(res.myDemandListVos.total);
      listDatas.value = res.myDemandListVos.list;
      dynamicColumn.value.splice(0, dynamicColumn.value.length);
      res.taxRateTitleInfoList.forEach((item, index) => {
        const obj: columnType = {
          label: '',
          prop: '',
          width: 140,
          fixed: false,
        };
        obj.label = item.titleName;
        obj.prop = item.titleProp;
        if (
          item.titleName === 'HS CODE' ||
          item.titleName === '商品名称及备注' ||
          item.titleName === '最惠国关税' ||
          item.titleName === '关税单位' ||
          item.titleName === '增值税'
        ) {
          obj.fixed = true;
        }
        if (item.titleName === '商品名称及备注') {
          obj.width = 526;
        }
        dynamicColumn.value.splice(index, 0, obj);
      });
    };
    // 获取表格信息
    const getTableDatas = (filterParams) => {
      const params = {
        pageNum: paginationParams.pageNum,
        pageSize: paginationParams.pageSize,
        ...props.customParams,
        ...queryForm.value,
        ...filterParams,
      };

      showLoading('.erp-body');
      setQueryParamsCache();
      httpPost(props.url, params).then((res) => {
        closeLoading();
        if (res.code === 200) {
          if (res.result.list) {
            // 是否展示索引
            if (props.isIndex) {
              listDatas.value = res.result.list.map((item, index) => {
                item.index = index + 1;
                return item;
              });
            } else {
              listDatas.value = res.result.list;
            }
            paginationParams.total = Number(res.result.total);
            ctx.emit('getParams', params);
          } else {
            // 是否是动态列
            if (!props.isDynamiColumn) {
              if (props.isFundingList) {
                listDatas.value = res.result.pageInfo.list;
                paginationParams.total = Number(res.result.pageInfo.total);
                ctx.emit('funding-total', res.result.crmFundingAmountCountVo);
              } else {
                listDatas.value = res.result;
              }
            } else {
              handleDynamicColumn(res.result);
            }
          }
        } else {
          listDatas.value = [];
        }
        // hooks 翻页问题有bug 强行同步分页值
        nextTick(() => {
          if (pageRef.value) {
            pageRef.value.internalCurrentPage = paginationParams.pageNum;
          }
        });
        tableLoad.value = true;
        // 设置默认选中激活
        setCurrentRow();
      });
    };
    // 获取查询参数
    // isRest true默认重置
    const queryParams = (filterParams: any, filterData: any, isRest: boolean) => {
      filterParams = Object.assign(props.customParams, filterParams);
      // 这里传递函数自定义拦截参数实现
      if (typeof props.queryFn === 'function' && props.queryFn !== null) {
        filterParams = Object.assign(filterParams, (props as any).queryFn(filterParams));
      }
      queryForm.value = filterParams;
      // 是否是初始化筛选
      if (isRest) {
        paginationParams.pageNum = 1;
        filterParams.pageNum = 1;
      }

      currentFilterData.value = filterData;
      getTableDatas(filterParams);
    };
    // 获取条数
    const currentSize = (size) => {
      paginationParams.pageSize = size;
      const filterParams = Object.assign(queryForm.value, { pageSize: size });

      getTableDatas(filterParams);
    };
    const getQueryForm = () => {
      return Object.assign(
        queryForm.value,
        {
          pageNum: 1,
          pageSize: paginationParams.pageSize,
        },
        props.customParams
      );
    };
    // 获取页码
    const currentChange = (page) => {
      paginationParams.pageNum = page;
      const filterParams = Object.assign(queryForm.value, { pageNum: page });

      getTableDatas(filterParams);
    };
    /**
     * 描述
     * @date 2021-01-29
     * @param {any} iskeep=true 是否保持参数
     * @returns {any}
     */
    const refreshTable = (iskeep = true) => {
      let filterParams = {};
      if (iskeep) {
        console.log(2222);
        filterParams = queryForm.value;
      } else {
        queryForm.value = (crmFilterRef.value as any).getRestParams(false);
        filterParams = Object.assign(
          {
            pageNum: 1,
            pageSize: paginationParams.pageSize,
          },
          queryForm.value,
          props.customParams
        );
        (filterParams as any).pageNum = 1;
        paginationParams.pageNum = 1;
        console.log(filterParams);
      }

      getTableDatas(filterParams);
    };

    const initFilter = () => {
      store.dispatch('getQueryParamsCache', props.id + 'ListQuery').then((data) => {
        let filterRef = crmFilterRef.value as any;

        if (data) {
          // 如果有缓存数据则使用缓存数据
          const { queryForm: queryParams, paginationParams: page, filterData } = data;
          paginationParams.pageNum = page.pageNum;
          paginationParams.pageSize = page.pageSize;
          queryForm.value = Object.assign(queryForm.value, queryParams);
          //如果只分页，不点击搜索条件 则使用默认条件
          if (filterData && filterData.length > 0) {
            //设置默认回填值
            filterRef.setDefaultFilterItemValue(toRaw(filterData));
          }
          filterRef.getQueryParams(filterRef.filterItems, false);
        } else {
          //这里只有初始化第一次才会触发，相当于init
          filterRef.getQueryParams(formatFilterOption.value, true);
        }
      });
    };

    // 执行初始化
    initFilter();

    return {
      pageRef,
      ...toRefs(paginationParams),

      crmTableRef,
      crmListRef,
      crmFilterRef,
      queryForm,
      getQueryForm,
      getTableDatas,
      queryParams,
      currentChange,
      currentSize,
      listDatas,
      refreshTable,
      completeLoad,
      getFilterLoad,
      formatFilterOption,
      dynamicColumn,
      handleDynamicColumn,
      rowClick,
      setCurrentRow,
    };
  },
});
