
import { httpPost } from '@/api';
import { useStore } from '@/store';
import { ConsType, CrmFilterOptions, ISelectItem } from '@/types/type';
import { hasEmptyObject } from '@/utils/publicMethods';
import dayjs from 'dayjs';
import { computed, defineComponent, PropType, Ref, ref, watch } from 'vue';

export default defineComponent({
  name: 'CrmFilter',
  props: {
    // todo props;
    options: {
      type: Array as PropType<CrmFilterOptions[]>,
    },
    isShowFilter: {
      type: Boolean,
      default: true,
    },
    isShowSearch: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['submit', 'load'],
  setup(props, ctx) {
    const store = useStore();
    const load = ref(false);
    const restOptions = JSON.parse(JSON.stringify(props.options));
    const dictOptions = computed(() => {
      return store.state.dict?.options;
    });
    const menuTreeData = ref([]);
    const filterItems: Ref<CrmFilterOptions[]> = ref([]);
    // 设置字典值,
    const setFilterItemOption = (options: CrmFilterOptions[]): CrmFilterOptions[] => {
      for (let index = 0; index < options.length; index++) {
        const v = options[index];
        if (v.options && typeof v.options === 'string' && (v.type === 'signSelect' || v.type === 'multiSelect')) {
          const dict = dictOptions.value;
          const opts = (dict as ConsType)[v.options];
          v.options = opts as ISelectItem[];
        }
      }
      return options;
    };

    const setWidth = (width: number, type?: string) => {
      if (type === 'daterange') {
        width = width || 340;
      } else {
        width = width || 160;
      }
      return { width: `${width}px` };
    };
    //处理字符最多9个
    const changeNumber = (e: string, data: any, index: number) => {
      let value = e.slice(0, 9);
      if (data.value) {
        data.value[index] = value;
      }
    };
    // 设置获取参数信息
    /** */
    /**
     * 描述
     * @date 2021-04-09
     * @param {any} data:CrmFilterOptions[]
     * @param {any} isRest=false 是否不触发emit
  
     * @returns {any}
     */
    const getQueryParams = (data: CrmFilterOptions[], isRest = true) => {
      const queryParams = {};
      data.forEach((v, index) => {
        // 设置为isHidden为 true的不加载

        if (v.type === 'input' || v.type === 'signSelect' || v.type === 'multiSelect') {
          if (v.type === 'multiSelect') {
            if (v.value.length > 0) {
              queryParams[v.prop] = v.value;
            } else {
              queryParams[v.prop] = [];
            }
          } else {
            if (v.value !== '' && v.value !== null) {
              queryParams[v.prop] = v.value;
            } else {
              if (!v.isValueNotToNull) {
                queryParams[v.prop] = null;
              } else {
                queryParams[v.prop] = '';
              }
            }
          }
        }
        if (v.type === 'cascader') {
          if (v.value && v.value.length > 0) {
            queryParams[v.prop] = v.value;
          } else {
            queryParams[v.prop] = [];
          }
        }
        if (v.type === 'time') {
          if (v.value && v.value[0]) {
            queryParams[v.prop[0]] = dayjs(v.value[0]).format('YYYY-MM-DD');
            queryParams[v.prop[1]] = dayjs(v.value[1]).format('YYYY-MM-DD');
          } else {
            queryParams[v.prop[0]] = null;
            queryParams[v.prop[1]] = null;
          }
        }
        if (v.type === 'number') {
          const value0 = v.value[0] ? v.value[0] : null;
          const value1 = v.value[1] ? v.value[1] : null;
          if (value0 !== '' && value0 !== null && value1 !== null && value1 !== '') {
            if (value0 > value1) {
              queryParams[v.prop[0]] = value1;
              queryParams[v.prop[1]] = value0;
              // 点击后自动置换
              filterItems.value[index].value[0] = value1;
              filterItems.value[index].value[1] = value0;
            } else {
              queryParams[v.prop[0]] = value0;
              queryParams[v.prop[1]] = value1;
            }
          } else {
            queryParams[v.prop[0]] = value0;
            queryParams[v.prop[1]] = value1;
          }
        }
      });

      ctx.emit('submit', queryParams, data, isRest);

      return queryParams;
    };

    /** 重置参数方法 */
    const getRestParams = (isQuery = true) => {
      filterItems.value = setFilterItemOption(JSON.parse(JSON.stringify(restOptions)));
      load.value = false;
      ctx.emit('load', false);
      setTimeout(() => {
        load.value = true;
        ctx.emit('load', true);
      }, 0);
      return isQuery ? getQueryParams(filterItems.value as CrmFilterOptions[]) : false;
    };
    //回填设置初始值
    const setDefaultFilterItemValue = (data: CrmFilterOptions[]) => {
      filterItems.value = setFilterItemOption(data);
    };

    // 默认触发一次
    // 只有保证获取到了字典以后再去渲染，避免一些时间冲突问题
    watch(
      dictOptions,
      () => {
        if (!hasEmptyObject(dictOptions.value)) {
          filterItems.value = setFilterItemOption(props.options as CrmFilterOptions[]);
          load.value = true;
          ctx.emit('load', true);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    const initMenuTree = async () => {
      const params = {};
      const res = await httpPost('/uac/menu/getTree', params);
      if (res.code === 200) {
        const data = res.result;
        if (!data) {
          return [];
        }
        menuTreeData.value = data[0].subMenu;
      }
    };
    initMenuTree();
    return {
      changeNumber,
      setDefaultFilterItemValue,
      filterItems,
      setWidth,
      getQueryParams,
      load,
      getRestParams,
      menuTreeData,
      initMenuTree,
    };
  },
});
